@import "../../styles/globals.scss";
.avatarContainer {
  border: 0.5px solid $gray2;
}
.donatorAvatar {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
.lastAvatarOverlay {
  @extend .rowCenter;
  @extend .absoluteFill;
  border-radius: 32px;
  background-color: rgba(0, 0, 0, 0.2);
}
.more {
  @extend .textOnPrimary;
  font-weight: bold;
  font-size: 16px;
  line-height: 0%;
  width: 15px;
  height: 10px;
}
.mr4 {
  margin-right: 4px;
}
