@import "../../styles/variable.scss";
.emptyStateContainer {
  padding: 96px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 251px;
  height: 168px;
  margin-bottom: 40px;
}
