@import "../../styles/globals.scss";

.inputContainer {
  @extend .rowStart;
  border: 1px solid $gray;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0px 16px;
}
.input {
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
}
.input::placeholder {
  @extend .body14;
  color: $textGray5;
}
.input:focus {
  outline-width: 0;
  outline: none;
}
