@import "../../styles/globals.scss";

.infoContainer {
  @extend .largeWhiteBoxShadow;
  background: $onPrimaryColor;
  border-radius: 8px;
  padding: calc(min(calc(100vh / 812 * 125), 125px) * 0.6 + 24px) 4px 32px 4px;
  margin-bottom: 24px;
}
.bannerSpacer {
  height: calc(100vh / 812 * 125 * 0.4);
}
.bannerContainer {
  @extend .rowCenter;
  position: absolute;
  z-index: 2;
  top: 0; //calc((100vw - 112px) * 0.6 * 125 / 267);;
  right: 0;
  left: 0;
}
.bannerImg {
  width: calc(min(calc(100vh / 812 * 267), 267px));
  height: calc(min(calc(100vh / 812 * 125), 125px));
}

.kolDemoImg {
  width: calc(min(calc(100vh / 812 * 342), 342px));
  height: calc(min(calc(100vh / 812 * 329), 329px));
  margin-bottom: 16px;
}
.content {
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

// .infoContainer {
//   @extend .largeWhiteBoxShadow;
//   background: $onPrimaryColor;
//   border-radius: 8px;
//   padding: calc((100vw - 112px) * 0.6 * 125 / 267 + 24px) 16px 30px 16px;
//   margin-bottom: 24px;
// }
// .bannerSpacer {
//   height: calc((100vw - 112px) * 0.4 * 125 / 267);
// }
// .bannerContainer {
//   @extend .rowCenter;
//   position: absolute;
//   z-index: 2;
//   top: 0; //calc((100vw - 112px) * 0.6 * 125 / 267);;
//   right: 0;
//   left: 0;
// }
// .bannerImg {
//   width: calc(100vw - 112px);
//   height: calc((100vw - 112px) * 125 / 267);
// }

// .kolDemoImg {
//   width: calc(100vw - 64px);
//   height: calc((100vw - 64px) * 329 / 342);
//   margin-bottom: 18px;
// }
