$primaryColor: #f54a00;
$primary10: #fb4b16;
$lightPrimary: #fef6f2;
$lightPrimary2: #ffede8;
$lightBlue: rgba(147, 209, 242, 0.2);
$lightBlue2: #e6e7f9;
$onPrimaryColor: #ffffff;
$primaryDarkColor: #00377a;
$onPrimaryDarkColor: #b8c7da;
$secondaryColor: #dd0071;
$secondaryLightColor: #fae6e4;
$textBlack: #15141f;
$textBlack2: #393939;
$textBlack3: #525252;
$textBlack4: #333333;
$textGray: #949ba5;
$textGray2: #c6c6c6;
$textGray3: #6f6f6f;
$textGray4: #8d8d8d;
$textGray5: #a8a8a8;
$textGray6: #e0e0e0;

$gray: #e0e0e0;
$gray2: #f4f4f4;
$gray3: #f8f8f8;
$gray4: #e2e8f0;
$background: #e5e5e5;

$textBlack2Opacity: rgba(17, 40, 67, 0.5);
$whiteColor: #ffffff;
$grayBackground: #f4f4f4;
$gray4: rgba(168, 168, 168, 0.5);
$placeholderColor: #a8a8a8;
$seperatorColor: rgba(0, 0, 0, 0.05);
$errorColor: #db3832;
$error20Color: #db383233;
$blueLink: #1697f4;
$green: #5cb157;
$warning: #f6b100;
$success: #209a4b;

$theme-colors: (
  "primary": $primaryColor,
);

$inputHeight: 48px;

// Bootstrap
$navbar-light-active-color: $secondaryColor;
$navbar-light-color: $primaryColor;
$navbar-light-hover-color: $secondaryColor;
$navbar-nav-link-padding-x: 0px;
$navbar-nav-link-padding-x: 0px;
$font-family-base: "Inter", sans-serif;
$btn-font-family: "Inter";
$btn-font-weight: 600;
$btn-line-height: 24px;
$btn-font-size: 16px;
$btn-padding-y: 12px;
$btn-padding-x: 48px;
$btn-border-radius: 8px;

$input-border-color: $gray2;
$input-placeholder-color: $placeholderColor;
$accordion-button-active-color: $textBlack;
$accordion-button-bg: $whiteColor;
$accordion-button-active-bg: $whiteColor;
$form-feedback-invalid-color: $errorColor;
$accordion-border-color: $gray2;
