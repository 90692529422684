@import "../../styles/globals.scss";
@import "../../styles/variable.scss";
.container {
  @extend .rowStart;
  background-color: $lightPrimary;
  padding: 18px 12px;
  border-radius: 8px;
}
.mbLogoContainer {
  padding: 9px 8px 9px 10px;
  background-color: $onPrimaryColor;
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.mbLogo {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
