@import "../../styles/globals.scss";

.arrowBackImg {
  width: 24px;
  height: 24px;
  @extend .mr10;
}
.searchInputContainer {
  background-color: $gray2;
  border: none !important;
}
.searchInputImg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.toolbar {
  height: 44px;
}
