@import "../../styles/globals.scss";

.toolbar {
  height: 44px;
}
.dataContainer {
  overflow-y: scroll;
  height: calc(100vh - max(36px, env(safe-area-inset-bottom)) - 68px);
  overscroll-behavior: none;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 88px;
}
.gridItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc((100vw - 32px) / 3);
  padding: 0px 8px;
}
.username {
  text-align: center;
  @extend .fontFamily;
  @extend .body13;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.searchInputImg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
