@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import "./variable.scss";

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
.link {
  color: #0d6efd !important;
  text-decoration: underline;
}
* {
  box-sizing: border-box;
}
.MuiDialog-paper.MuiDialog-paperScrollPaper{
  overflow: hidden;
  border-radius: 16px;
}

.noOpacity {
  opacity: 1;
}
.opacity0 {
  opacity: 0;
}

.textCenter {
  text-align: center;
}
.toolbarTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  font-family: "Inter";
}

.popupTitle {
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.popupTitle2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 18px;
  }
}
.subTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.title20 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.smallTitle {
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}
.title14 {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.title24 {
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.popupBody {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

.body20 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}

.body24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.label {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.lineHeight22 {
  line-height: 22px !important;
}

// New text style
.title22 {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
}
.title18 {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
}

.title16 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.title15 {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}

.title14 {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.title12 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.subTitle16 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.subTitle15 {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
}

.subTitle14 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.subTitle13 {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
}

.subTitle12 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.body10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}

.body18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.body16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.body14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.body13 {
  font-size: 13px;
  line-height: 19px;
  font-style: normal;
  font-weight: 400;
}

.body12 {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
}

.body11 {
  font-size: 11px;
  line-height: 15px;
  font-style: normal;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.bold {
  font-weight: bold;
}
.w400 {
  font-weight: 400;
}

.italic {
  font-style: italic;
}
.positionAbsolute {
  position: absolute;
}
.positionRelative {
  position: relative;
}
.absoluteFill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rowStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rowAllStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.rowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rowSpaceBetweenAlignStart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.rowResponsive {
  display: flex;
  flex-direction: row;
  @media (max-width: 576px) {
    flex-direction: column;
  }
  justify-content: flex-start;
  align-items: center;
}
.marginHori16 {
  margin: 0 16px;
}
.paddingHori16 {
  padding: 0 16px;
}
.paddingHori12 {
  padding: 0 12px;
}
.columnAlignCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rowScroll {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.textBlack {
  color: $textBlack;
}
.fontFamily {
  font-family: "Inter";
}
.textBlack2 {
  color: $textBlack2;
}
.textBlack3 {
  color: $textBlack3;
}
.textBlack4 {
  color: $textBlack4;
}
.textBlack2Opacity {
  color: $textBlack2Opacity;
}
.textGray {
  color: $textGray;
}
.textGray2 {
  color: $textGray2;
}
.textGray3 {
  color: $textGray3;
}
.textGray4 {
  color: $textGray4;
}
.textGray5 {
  color: $textGray5;
}
.textGray6 {
  color: $textGray6;
}
.textPlaceholder {
  color: $placeholderColor;
}

.textPrimary {
  color: $primaryColor;
}
.textSecondary {
  color: $secondaryColor;
}
.textOnPrimary {
  color: $onPrimaryColor;
}
.textSuccess {
  color: $success;
}
.textWarning {
  color: $warning;
}
.textError {
  color: $errorColor;
}
.viewMoreAnchor:hover {
  color: unset !important;
}
.noDecoration {
  text-decoration: none;
}

.inactiveChipContainer {
  @extend .body13;
  @extend .rowCenter;
  background-color: $gray2;
  padding: 10px 16px;
  color: $textGray3;
  border-radius: 8px;
}

.bgPrimary {
  background-color: $primaryColor;
}

.bgOnprimary {
  background-color: $onPrimaryColor;
}

.bgSecondary {
  background-color: $secondaryColor;
}
.bgGray2 {
  background-color: $gray2;
}
.bgGray3 {
  background-color: $gray3;
}
.bgGray4 {
  background-color: $gray4;
}

.largeWhiteBoxShadow {
  box-shadow: 0px 109.322px 87.4576px rgba(112, 112, 112, 0.02),
    0px 70.8569px 51.2194px rgba(112, 112, 112, 0.018),
    0px 42.1092px 27.8569px rgba(112, 112, 112, 0.0104),
    0px 21.8644px 14.2119px rgba(112, 112, 112, 0.005),
    0px 8.90772px 7.12618px rgba(112, 112, 112, 0.0096),
    0px 2.02448px 3.44162px rgba(112, 112, 112, 0.012037);
}
.whiteBoxShadow {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 30px rgba(25, 25, 46, 0.06);
  border-radius: 8px;
}

.whiteBoxShadow2 {
  background: $onPrimaryColor;
  box-shadow: 0px 4px 14px rgba(0, 16, 29, 0.06);
  border-radius: 8px;
}

.cardHorizontal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 2px 53px rgba(0, 0, 0, 0.05));
  background-color: $whiteColor;
}
.pt4 {
  padding-top: 4px;
}
.pt8 {
  padding-top: 8px;
}
.pt16 {
  padding-top: 16px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml2 {
  margin-left: 2px;
}
.ml16 {
  margin-left: 16px;
}
.mt100 {
  margin-top: 100px;
}
.mt90 {
  margin-top: 90px;
}
.ph30 {
  padding: 0px 30px;
}
.ph16 {
  padding: 0px 16px;
}
.ph36 {
  padding: 0px 36px;
}
.mt56 {
  margin-top: 56px;
}
.mt32 {
  margin-top: 32px;
}
.mt22 {
  margin-top: 22px;
}
.mt14 {
  margin-top: 14px;
}
.mt16 {
  margin-top: 16px;
}
.mt10 {
  margin-top: 10px;
}
.mt8 {
  margin-top: 8px;
}
.mt6 {
  margin-top: 8px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr12 {
  margin-right: 12px;
}
.mr14 {
  margin-right: 14px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb36 {
  margin-bottom: 36px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb21 {
  margin-bottom: 21px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb44 {
  margin-bottom: 44px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb96 {
  margin-bottom: 96px;
}
.mb22 {
  margin-bottom: 22px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb2 {
  margin-bottom: 2px;
}

.mb50 {
  margin-bottom: 50px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb22 {
  padding-bottom: 22px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb32 {
  padding-bottom: 32px;
}
.mr6 {
  margin-right: 6px;
}
.mr10 {
  margin-right: 10px;
}
.pl16 {
  padding-left: 16px;
}
.pr16 {
  padding-right: 16px;
}
.flex1 {
  flex: 1;
}
.fullWidth {
  width: 100%;
}
.background {
  background-color: $background;
}
.verifiedCheckSmall {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  // margin-bottom: 2px;
  vertical-align: middle;
}
.tabularNum {
  font-variant-numeric: tabular-nums;
}

.line {
  width: 100%;
  height: 1px;
  background-color: $seperatorColor;
}
.required {
  color: red;
}
.invalidText {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $errorColor;
}
.helpText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 0.25rem;
  color: $gray3;
}

.rowMenuItem {
  padding: 26px 20px;
  background-color: $onPrimaryColor;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(35, 87, 234, 0.06),
    0px 3px 4px rgba(35, 87, 234, 0.06), 0px 1px 5px rgba(35, 87, 234, 0.06);
}
.rowMenuItem2 {
  padding: 16px;
  background-color: $onPrimaryColor;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(35, 87, 234, 0.06),
    0px 3px 4px rgba(35, 87, 234, 0.06), 0px 1px 5px rgba(35, 87, 234, 0.06);
}
.ellipsisOne {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.prewrap {
  white-space: pre-wrap;
}
.verticalMiddle {
  vertical-align: middle;
}
.breakWord {
  word-break: break-word;
  width: 100%;
}
.sliderDots {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.dotActive {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: $primaryColor;
}

.dotInactive {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: $lightPrimary2;
}

/* Loading */
@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Loading large */

.loading div {
  position: absolute;
  width: 64px;
  height: 64px;
  border: 6px solid $primaryColor;
  border-top-color: transparent;
  border-radius: 50%;
}

.loading div {
  animation: loading 1s linear infinite;
  top: 100px;
  left: 100px;
}

.loading-spinner-rolling {
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
  margin: auto;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.loading div {
  box-sizing: content-box;
}

.loading div {
  position: absolute;
  width: 64px;
  height: 64px;
  border: 6px solid $primaryColor;
  border-top-color: transparent;
  border-radius: 50%;
}

.loading div {
  animation: loading 1s linear infinite;
  top: 100px;
  left: 100px;
}

.loading-spinner-rolling {
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
  margin: auto;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.loading div {
  box-sizing: content-box;
}

/* Loading small */
.loadingSmall div {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 3px solid $primaryColor;
  border-top-color: transparent;
  border-radius: 50%;
}

.loadingSmall div {
  animation: loading 1s linear infinite;
  top: 32px;
  left: 32px;
}

.loadingSmall-spinner-rolling {
  width: 64px;
  height: 64px;
  display: block;
  overflow: hidden;
  margin: auto;
}

.loadingSmall {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.loadingSmall div {
  box-sizing: content-box;
}
