@import "../../styles/globals.scss";

.targetContainer {
  border-radius: 8px;
  border: 1px solid $gray2;
}
.contentContainer {
  background: $onPrimaryColor;
  box-shadow: 0px 4px 30px rgba(25, 25, 46, 0.06);
  border-radius: 0px 0px 8px 8px;
}
.image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-size: cover;
  width: 100%;
  height: calc((100vw - 32px) * 240 / 343);
  display: flex;
  flex-direction: column;
}
.imageContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 44.66%,
    rgba(0, 0, 0, 0.584) 100%
  );
}
.creatorCard {
  background: linear-gradient(
    282.63deg,
    rgba(255, 255, 255, 0.066) 24.04%,
    rgba(255, 255, 255, 0.15) 79.72%
  );
  mix-blend-mode: normal;
  opacity: 0.85;
  backdrop-filter: blur(15px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 62px;
  z-index: 1;
}
.creatorContent {
  padding: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  // height: 62px;
  z-index: 2;
}
// .avatarInnerContainer {
// }
.donateButton {
  width: fit-content;
  height: 32px;
  padding: 0px 16px;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
