@import "../../styles/variable.scss";

.avatarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 100%;
  border: 0.5px solid $gray2;
}

.gradientAvatarContainer {
  padding: 2px;
  background: linear-gradient(180deg, #ff6f32 0%, #fa2d00 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 100%;
}
.whiteContainer {
  padding: 2px;
  background: $onPrimaryColor;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.avatar {
  border-radius: 100%;
}
