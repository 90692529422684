@import "../../styles/globals.scss";
.content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.topSpacer {
  height: 60px;
}
.donateImg {
  width: 266px;
  height: 228px;
}
.moneyQuickSelectorContainer {
  @extend .whiteBoxShadow2;
  @extend .rowStart;
  padding: 10px;
}
.moneyInput {
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: bold;
  color: $primaryColor !important;
  height: 56px !important;
}
.moneyChip {
  @extend .inactiveChipContainer;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22.4px !important;
}
.moneyChip:active {
  color: $onPrimaryColor !important;
  background-color: $primaryColor !important;
}
.moneyInput::placeholder {
  font-size: 24px !important;
  font-weight: bold !important;
}
.donateMessageItem {
  width: fit-content;
  justify-content: flex-start !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  font-weight: 19px !important;
  color: $textBlack3;
  padding: 7px 15px !important;
}
.donateMessageInput {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
