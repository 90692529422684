@import "../../styles/globals.scss";
@import "../../styles/variable.scss";
.container {
  @extend .rowStart;
  background-color: $lightPrimary;
  padding: 10px 14px;
  border-radius: 8px;
}
.forwardImg {
  width: 36px;
  height: 36px;
  margin-left: 8px;
}
