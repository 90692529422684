@import "../../styles/globals.scss";

.bannerImg {
  width: 100%;
  height: calc(100vw * 210 / 375);
  object-fit: cover;
}
.content {
  padding: 28px 24px;
}
