@import "../../styles/globals.scss";

.showAllText {
  color: $primaryColor;
}
.targetContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
