@import "../../styles/variable.scss";
.container {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: $gray;
  overflow: hidden;
}
.progressFinished {
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(270deg, #209a4b 0%, #5ac926 73.96%);
}
.progress {
  height: 8px;
  background: linear-gradient(270deg, #ff5238 0%, #ffc75a 100%);
  border-radius: 4px;
}
