@import "../../styles/variable.scss";
@import "../../styles/globals.scss";
.toolbarContainerGradient {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-image: url("../../assets/images/toolbar-gradient.png");
  background-size: cover;
}

.toolbarContainerNormal {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-color: $onPrimaryColor;
  background-size: cover;
}
.toolbar {
  @extend .rowStart;
  position: relative;
  width: 100%;
}
.titleContainer {
  @extend .rowCenter;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  padding: 0px 50px;
}
.backImg {
  width: 24px;
  height: 24px;
}
.backIconPlaceholder {
  width: 20px;
  height: 20px;
}
.suffixButtonContainer {
  @extend .rowCenter;
  position: absolute;
  background: $onPrimaryColor;
  right: 0px;
  box-shadow: 0px 4px 30px rgba(25, 25, 46, 0.06);
  border-radius: 8px;
  padding: 11px;
  height: 38px;
  width: 38px;
}
.suffixButton {
  width: 16px;
  height: 16px;
}
