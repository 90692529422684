@import "../../styles/globals.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}
.headerContent {
  flex: 1;
}
.searchIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $onPrimaryColor;
  box-shadow: 0px 4px 30px rgba(25, 25, 46, 0.06);
  border-radius: 8px;
  padding: 11px;
}
.searchIcon {
  width: 16px;
  height: 16px;
}
.image {
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 192 / 343);
  padding: 0px 5px;
  border-radius: 16px;
  object-fit: cover;
}
.sliderDots {
  position: absolute;
  bottom: 54px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.homeNoAccountTargetImg {
  width: 166px;
  height: 155px;
  margin-bottom: 20px;
}

.popupMainContent {
  //height: 480px;
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 16px;
  background: #FFFFFF;
}

.bannerPopup {
  width: 430px;
  padding: 0 10px 10px;
  border: 1px solid rgba(245, 74, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, 0);
}
.bannerPopupRound1 {
  padding: 0 10px 10px;
  border: 1px solid rgba(245, 74, 0, 0.6);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;

}
.bannerPopupRound2 {
  padding: 0 10px 10px;
  background: linear-gradient(0deg, #FF5238 0%, #FFC75A 100%);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.bannerPopupImg {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.logoImg {
  position: absolute;
  z-index: 1;
  width: 80px;
  height: 35px;
  left: 0;
  top: 0;
}
.groupImg {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
}

.txtThank {
  font-family: 'SVN-Alluring';
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
}
.nameDonator {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #F54A00;
}
.txtDesc {
  font-weight: 600;
  font-size: 14px;
  color: #393939;
  margin-top: 10px;
}

.txtDesc1 {
  font-weight: 600;
  font-size: 14px;
  color: #393939;
  margin: 0 20px 60px;
  word-break: break-word;
}

.dialogDesc {
  margin-top: 280px !important;
  text-align: center;
}

@media (max-width: 400px) {
  .bannerPopup {
    width: 400px;
  }

  .dialogDesc {
    margin-top: 260px !important;
  }
}

@media (min-width: 431px) {
  .popupMainContent {
    width: 380px;
  }

  .bannerPopup {
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: unset;
    position: relative;
    top: 0;
    left: unset;
    transform: unset;
  }
  .bannerPopupRound1 {
    padding: 0;
    border: 0;

  }
  .bannerPopupRound2 {
    padding: 0;
    border-radius: 0;
  }

  .bannerPopupImg {
    width: 100%;
    height: 60%;
    border-radius: 0;
  }
  .dialogDesc {
    margin-top: 10px !important;
  }
}

