@import "../../styles/globals.scss";
.profileBox {
  @extend .columnAlignCenter;
  @extend .largeWhiteBoxShadow;
  padding: 36px 20px 24px 20px;
  margin-bottom: 24px;

  border-radius: 8px;
}
.content {
  padding-left: 16px;
  padding-right: 16px;
}
.topSpacer {
  height: 56px;
}
.avatarContainer {
  @extend .rowCenter;
  position: relative;
  top: 16px;
  z-index: 1;
}
.avatarStar {
  @extend .rowCenter;
  background-image: url("../../assets/images/avatar-star.png");
  background-size: cover;
  width: 133px;
  height: 120px;
}
.socialIconContainer {
  @extend .rowCenter;
  background: $onPrimaryColor;
  box-shadow: 0px 2px 16px rgba(228, 233, 239, 0.6);
  border-radius: 8px;
  width: 36px;
  height: 36px;
}
.socialIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.primaryLineLeft {
  margin-right: 16px;
  width: 100px;
  height: 1px;
  background-color: $textGray6;
}
.primaryLineRight {
  margin-left: 16px;
  width: 100px;
  height: 1px;
  background-color: $textGray6;
}
.quotesImg {
  width: 18px;
  height: 13px;
}
.cardImg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.warningToast {
  @extend .rowStart;
  background-color: $warning;
  padding: 10px 16px;
  border-radius: 8px;
}
.toastIcon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}
.scrollContainer {
  overflow-y: scroll;
  height: calc(100vh - var(--statusbar-height));
  overscroll-behavior: none;
}
