@import "../../styles/variable.scss";
.emptyStateContainer {
  padding: 96px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginFailedImg {
  width: 251px;
  height: 169px;
  margin-bottom: 54px;
}
.accessDenyImg {
  width: 251px;
  height: 153px;
  margin-bottom: 54px;
}
