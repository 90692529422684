@import "../../styles/globals.scss";
.container {
  background-size: cover;
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 240 / 343);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray2;
}
.info {
  padding: 16px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0px 0px 8px 8px;
}
.targetName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
