@import "../../styles/variable.scss";
@import "../../styles/globals.scss";
.content {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 23px 0px 0px 0px;
  position: relative;
  top: -40px;
  z-index: 10;
  background-color: $onPrimaryColor;
  // box-shadow: 0px 4px 30px rgba(25, 25, 46, 0.09);
  border-radius: 20px 20px 0px 0px;
}
.targetMoneyImg {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.bannerImage {
  width: 100vw !important;
  height: calc(100vw * 262 / 375);
  object-fit: cover;
}
.bannerRound {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  width: 100%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: $onPrimaryColor;
  z-index: 50;
}
.bannerDot {
  position: absolute;
  bottom: 54px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.imageIndicator {
  @extend .rowCenter;
  background: rgba(140, 140, 140, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  padding: 0px 10px;
  height: 36px;
  position: absolute;
  right: 16px;
  top: calc(var(--statusbar-height) + 6px);
  z-index: 1;
}
.bottomButtonSpacer {
  height: calc(16px + max(0px, env(safe-area-inset-bottom)) + 16px);
}
.infoImg {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
