@import "../../styles/variable.scss";
.emptyStateContainer {
  padding: 56px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noInternetImg {
  width: 300px;
  height: 206px;
  margin-bottom: 48px;
}
