@import "../../styles/globals.scss";

.accountContainer {
  width: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.accountFullname {
  width: inherit;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @extend .breakWord;
}
